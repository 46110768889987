import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { sessionDataContext } from "../App";
import Stepper from "../components/stepper";
import ThankyouPage from "../components/Thankyou";
import ThemeSpinner from "../components/ThemeSpinner";
import { getQuestions } from "../service/games";
import axiosInstance from "../utils/axiosInstance";

let TOTAL_SCORE = 100;

const Mcqs = ({
  updateActiveRound,
  SessionRecord,
  overallScore,
  setOverAllScore,
}) => {
  const [questions, setQuestions] = useState(null);

  const [active, setActive] = useState(null);

  const [timeLeft, setTimeLeft] = useState(0);

  const [currentQuestion, setCurrentQuestion] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const [endGame, setEndGame] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const [selected, setSelected] = useState(false);

  const [leaderBoardData, setLeaderBoardData] = useState();

  const [selectedOptions, setSelectionOptions] = useState([]);

  const [searchParams] = useSearchParams();

  const customerId = searchParams.get("customer_id");

  const [sessionData, setSessionData] = useContext(sessionDataContext);

  const [score, setScore] = useState(0);

  const [attempt, setAttempt] = useState(0);

  const handleClose = () => setShowModal(false);

  const handleShow = () => setShowModal(true);

  useEffect(() => {
    GetQuestion();
  }, []);

  const GetLeaderBoard = async () => {
    try {
      const id = customerId ? btoa(customerId) : btoa("753982145");
      const res = await axiosInstance.get(`/api/leaderboard/get`, {
        headers: {
          user_token: id,
        },
      });
      setLeaderBoardData(res?.data?.data);
    } catch (e) {
      //   window.location.href = "*";
    }
  };

  const GetQuestion = async () => {
    try {
      setIsLoading(true);
      const params = {
        module_type: "mcqs",
        audience_group_id: SessionRecord.audience_group_id,
        question_group_id: SessionRecord.question_group_id,
      };
      let res = await getQuestions(params);

      if (res?.data?.data?.length) {
        setQuestions(res?.data?.data);
        updateActiveRound(2, res?.data?.data[currentQuestion]?.id);
        setTimeLeft(res?.data?.data[currentQuestion]?.time_limit);
      }
    } catch (error) {
      console.log("error detected");
    } finally {
      setIsLoading(false);
    }
  };

  const handleOptionClick = (choiceId) => {
    try {
      let temp = [...selectedOptions];

      if (choiceId?.is_correct && !score) {
        setScore(TOTAL_SCORE);
        setOverAllScore((pre) => pre + TOTAL_SCORE);
        setAttempt(1);
      } else {
        if (!choiceId?.is_correct) {
          let total = score ? score - 50 : TOTAL_SCORE - 50;
          if (total >= 0) {
            TOTAL_SCORE = 0;
            setScore(total);
          }
          setAttempt((pre) => pre + 1);
        } else if (choiceId?.is_correct) {
          setOverAllScore((pre) => pre + score);
          TOTAL_SCORE = 100;
        }
      }
      temp.push(choiceId.id);
      setSelectionOptions(temp);
      setActive(choiceId);
    } catch (e) {
      console.log(e.message);
    }
  };

  const submitAnswerIfnotSelected = async () => {
    if (questions) {
      try {
        const data = {
          question_id: questions[currentQuestion]?.id,
          answer: active?.option || "",
          time_spent: timeLeft,
          attempts: attempt,
          score: score,
        };

        setIsSubmitLoading(true);
        const id = customerId ? btoa(customerId) : btoa("753982145");

        await axiosInstance.post(`/question-answer/submit-answer`, data, {
          headers: { user_token: id },
        });

        setIsSubmitLoading(false);
      } catch (e) {
        setIsSubmitLoading(false);
        const customer = customerId ? customerId : "753982145";
        window.location.href = `/leaderboard?customer_id=${customer}`;
      }
    }
  };

  const handleNext = async () => {
    if (questions) {
      if (active?.option) {
        try {
          const data = {
            question_id: questions[currentQuestion]?.id,
            answer: active?.option || "",
            time_spent: timeLeft,
            attempts: attempt,
            score: score,
          };
          setIsSubmitLoading(true);

          const id = customerId ? btoa(customerId) : btoa("753982145");

          await axiosInstance.post(`/question-answer/submit-answer`, data, {
            headers: {
              user_token: id,
            },
          });

          setIsSubmitLoading(false);
        } catch (e) {
          setIsSubmitLoading(false);
          const customer = customerId ? customerId : "753982145";
          window.location.href = `/leaderboard?customer_id=${customer}`;
        }
      }

      if (currentQuestion < questions?.length - 1) {
        setCurrentQuestion(currentQuestion + 1);
        setTimeLeft(questions[currentQuestion + 1]?.time_limit);
        setActive(null);
        setScore(0);
        updateActiveRound(2, questions[currentQuestion + 1]?.id);
      } else {
        // setEndGame(true);
        // updateActiveRound(3);
        updateActiveRound(2, null, "completed");
      }

      setActive(null);
    }
  };

  useEffect(() => {
    if (timeLeft === 0) {
      submitAnswerIfnotSelected();
    }
  }, [timeLeft]);

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else if (timeLeft === 0) {
      handleNext();
    }
  }, [timeLeft]);

  return (
    <React.Fragment>
      {!endGame ? (
        !isLoading ? (
          <div className="spin-wheel container mt-5 mb-5 mcqs">
            <div className="heading mb-5 d-flex flex-column justify-content-center align-items-center w-100">
              {/* Stepper and Leader board button */}
              <div className="w-100 d-flex justify-content-between">
                <div className="mx-auto">
                  <Stepper
                    activeStep={currentQuestion}
                    totalSteps={questions?.length}
                  />
                </div>
              </div>

              <div className="quiz-component">
                <Row>
                  <Col md={12} lg={8} className="question-sec bg-white">
                    {!!questions?.length && (
                      <div
                        key={questions[currentQuestion]?.id}
                        className="quiz w-100"
                      >
                        <h3>{questions[currentQuestion]?.question}</h3>
                        <div className="options mt-5 d-flex justify-content-center align-items-center user-select-none">
                          {questions[currentQuestion]?.question_options?.map(
                            (c) => (
                              <p
                                className={`cursor ${
                                  active?.id === c?.id && "active"
                                } ${
                                  selectedOptions.includes(c?.id) &&
                                  !c?.is_correct &&
                                  "wrong-option"
                                } `}
                                onClick={
                                  active?.is_correct ||
                                  selectedOptions.includes(c?.id)
                                    ? () => {}
                                    : () => handleOptionClick(c)
                                }
                                key={c?.id}
                              >
                                {c?.option}
                              </p>
                            )
                          )}
                        </div>
                      </div>
                    )}
                  </Col>

                  <Col md={12} lg={4}>
                    <div className="duration-details d-flex flex-column justify-content-center align-items-center">
                      <div className="score d-flex flex-column justify-content-center align-items-center bg-white">
                        <p>Overall scroll</p>
                        <div className="score-count">{overallScore}</div>
                      </div>

                      <div className="time d-flex flex-column justify-content-center align-items-center bg-white">
                        <p>Time</p>
                        <div
                          className={`time-left  ${
                            timeLeft === 0 ? "bg-danger" : "bg-black"
                          } `}
                        >
                          Time Left <span className="ms-2">: {timeLeft}s</span>
                        </div>
                      </div>

                      <div className="score d-flex flex-column justify-content-center align-items-center bg-white">
                        <p>Score</p>
                        <div className="score-count">{score}</div>
                      </div>

                      <Button
                        disabled={
                          timeLeft < 1 || !active?.is_correct ? true : false
                        }
                        className="btn w-100"
                        onClick={handleNext}
                      >
                        {isSubmitLoading ? <ThemeSpinner /> : "Next"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center">
            Loading...
          </div>
        )
      ) : (
        <div className="thank-you d-flex justify-content-center align-items-center flex-column">
          <ThankyouPage
            text={
              "Thank you for submitting your answer. Get ready for the next round if you qualify!"
            }
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default Mcqs;
