import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import axiosInstance from "../utils/axiosInstance";
import { Col, Row } from "react-bootstrap";
import CustomSpinner from "../components/Spinner";

function LeaderBoard() {
  const [leaderBoardData, setLeaderBoardData] = useState([]);

  const [loading, setLoading] = useState(true);

  const [customerId, setCustomerId] = useState("");

  const navigate = useNavigate();

  const GetLeaderBoard = async () => {
    try {
      let id = localStorage.getItem("i");
      setCustomerId(atob(id));
      const res = await axiosInstance.get(`/leaderboard/get`, {
        headers: {
          user_token: id,
          // user_token: "ODkzNjY2OTU3NzQ4OQ==",
        },
      });

      setLeaderBoardData(res?.data?.data);
      setLoading(false);

      // setLeaderBoardData([
      //   ...res?.data?.data,
      //   ...res?.data?.data,
      //   ...res?.data?.data,
      //   ...res?.data?.data,
      //   ...res?.data?.data,
      // ]);
    } catch (e) {
      console.log(e.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    GetLeaderBoard();
  }, []);

  const navigateToSpinWheel = async () => {
    try {
      navigate("/spinwheel");
    } catch (e) {
      console.log(e.message);
    }
  };

  const afterThreeRecord = leaderBoardData?.slice(3);

  if (loading) return <CustomSpinner />;

  if (!leaderBoardData?.length)
    return (
      <div className="d-flex flex-column align-items-center justify-content-center vh-100">
        <div className="fs-3">Record Not Found</div>
        <br />
        <br />
        <Link to={"https://playluckyduck.com/"}>
          <button
            className={`rounded-3 fw-bold border-0 py-2 px-4 next-btn text-white`}
          >
            Go to home
          </button>
        </Link>
      </div>
    );
  const getOrdinal = (n) => {
    if (n > 3 && n < 21) return renderOrdinal(n, "th"); // Covers 11th to 19th (special case)
    const lastDigit = n % 10;
    switch (lastDigit) {
      case 1:
        return renderOrdinal(n, "st");
      case 2:
        return renderOrdinal(n, "nd");
      case 3:
        return renderOrdinal(n, "rd");
      default:
        return renderOrdinal(n, "th");
    }
  };

  // Function to return styled JSX
  const renderOrdinal = (num, suffix) => (
    <h1
      style={{
        position: "relative",
        display: "inline-block",
        fontSize: "2rem",
        marginRight: "10px",
      }}
    >
      {num}
      <sup
        style={{
          fontSize: "1rem",
          position: "absolute",
          top: "0",
          right: "-10px",
        }}
      >
        {suffix}
      </sup>
    </h1>
  );
  return (
    <div className="container mt-5 ">
      <Row>
        <Col sm={12}>
          <h1 className="text-center mb-4">Leaderboard</h1>
        </Col>
      </Row>

      {/* <Row className="mb-4 d-flex align-items-center justify-content-center gap-3">
        {[0, 1, 2].map(
          (item, index) =>
            leaderBoardData[item] && (
              <Col xs={12} xl={4} key={index}>
                <div className="card p-3 card-text h-100">
                  <div className="d-flex align-items-center gap-2 overflow-hidden">
                    <div key={index}>{getOrdinal(index + 1)}</div>
                    <img
                      src={leaderBoardData[item]?.user?.image_url}
                      alt="Image not found"
                      style={{
                        height: "60px",
                        width: "60px",
                        objectFit: "cover",
                        borderRadius: 10,
                      }}
                    />
                    <div className="d-flex flex-column">
                      <div>{leaderBoardData[item]?.user?.name}</div>
                      <div>{leaderBoardData[item]?.user?.email}</div>
                    </div>
                    <div className="w-100 d-flex justify-content-end">
                      {leaderBoardData[item]?.score_sum}
                    </div>
                  </div>
                </div>
              </Col>
            )
        )}
      </Row> */}

      <Row>
        <Col>
          <table className="table championsTable">
            <thead>
              <tr>
                <th className="p-3">Rank</th>
                <th className="p-3">Image</th>
                <th className="p-3">Name</th>
                <th className="p-3">Email</th>
                <th className="p-3">Score</th>
                <th className="p-3">Spin</th>
              </tr>
            </thead>

            <tbody>
              {leaderBoardData?.length &&
                leaderBoardData?.map((item, index) => (
                  <tr
                    key={index}
                    className={`text-secondary ${index <= 2 && "top-rated"}`}
                    style={{
                      verticalAlign: "middle",
                    }}
                  >
                    <td key={index}>{getOrdinal(index + 1)}</td>
                    <td>
                      <img
                        src={item?.user?.image_url}
                        alt="Image not found"
                        className="userImage"
                      />
                    </td>
                    <td className={"elseUser"}>{item?.user?.name}</td>
                    <td className={"elseUser"}>{item?.user?.email}</td>
                    <td className={"elseUser"}>{item?.score_sum}</td>
                    <td>
                      {item?.user?._id == customerId && index > 2 && (
                        <button
                          disabled={item?.spin_wheel_played == 1}
                          className={
                            item?.spin_wheel_played == 1
                              ? `btn btn-secondary`
                              : `btn btn-warning`
                          }
                          onClick={navigateToSpinWheel}
                        >
                          Spin Wheel
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Col>
      </Row>

      <div></div>
    </div>
  );
}

export default LeaderBoard;
