import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { sessionDataContext } from "../App";
import CustomSpinner from "../components/Spinner";
import axiosInstance from "../utils/axiosInstance";
import GuessGame from "./GuessGame";
import Mcqs from "./Mcqs";

// <GuessGame />
// <Mcqs />
// <Dashboard />
// <DoorCOmponent />

export default function Games() {
  const [data, setData] = useState(null);
  const [activeRound, setActiveRound] = useState(1);
  const [sessionStart, setSessionStart] = useState(false);
  const [userActiveRound, setUserActiveRound] = useState({});
  const [searchParams] = useSearchParams();
  const customerId = searchParams.get("customer_id");
  const [sessionData, setSessionData] = useContext(sessionDataContext);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const navigate = useNavigate();
  const [score, setScore] = useState(0);

  // console.log(score);

  useEffect(() => {
    fetchSession();

    try {
      localStorage.setItem("i", btoa(customerId));
    } catch (e) {
      console.log(e.message);
    }
  }, []);

  const fetchSession = async () => {
    try {
      const id = customerId ? btoa(customerId) : btoa("753982145");

      const res = await axiosInstance.get(`/session/check-session`, {
        headers: {
          user_token: id,
        },
      });

      if (res?.status === 200) {
        setSessionStart(res?.data?.data);
        getActiveRound(res?.data?.data);
      }
    } catch (error) {
      if (error.status == 405) {
        navigate(`/leaderboard`);
        return;
      }
    } finally {
      setLoading(false);
    }
  };

  const getActiveRound = async (session) => {
    try {
      const id = customerId ? btoa(customerId) : btoa("753982145");

      const res = await axiosInstance.get(`/round?session_id=${session?.id}`, {
        headers: {
          user_token: id,
        },
      });

      if (res?.data?.code === 200) {
        if (res?.data?.data?.status === "completed") {
          navigate(`/leaderboard`);
          return;
        }

        setUserActiveRound(res?.data?.data);
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setLoading2(false);
    }
  };

  const updateActiveRound = async (round, question_id, status) => {
    try {
      setActiveRound(round);

      if (status) {
        const id = customerId ? customerId : "753982145";
        navigate(`/leaderboard?customer_id=${id}`);
      }

      const id = customerId ? btoa(customerId) : btoa("753982145");

      const body = {
        session_id: sessionStart?.id,
        question_id: question_id,
        round: round,
        ...(status && { status: status }),
      };

      await axiosInstance.post(`/round`, body, {
        headers: {
          user_token: id,
        },
      });
    } catch (e) {
      console.log(e.message);
    }
  };

  if (loading && loading2) return <CustomSpinner />;

  if (!sessionStart)
    return (
      <div className="d-flex align-items-center justify-content-center vh-100">
        <div className="fs-3">Session not found !</div>
      </div>
    );

  const GAMESVIEW = {
    1: (
      <GuessGame
        updateActiveRound={updateActiveRound}
        activeRound={activeRound}
        SessionRecord={sessionStart}
        score={score}
        setScore={setScore}
      />
    ),
    2: (
      <Mcqs
        updateActiveRound={updateActiveRound}
        activeRound={activeRound}
        SessionRecord={sessionStart}
        overallScore={score}
        setOverAllScore={setScore}
      />
    ),
  };

  return <div>{GAMESVIEW[activeRound]}</div>;
}
